import React, { useState, useEffect, useRef } from 'react';
import {
    Button,
    Popconfirm, Form, message
} from 'antd';
import {
    getFullLink,
    parseQueryToString
} from '@/utils'
import YzhSelect from '@com/YzhSelect'
import {
    hasPermission,
    // permissionCodes as pCodes
} from '@/routes/permission'
import {
    selectionUrl, o2oUrl,
    supplierUrl, enterpriseUrl,
    appUrl,
    mallUrl, adminUrl, ocUrl, dataCenterUrl
} from '@/api/baseUrl';
import Cookies from 'js-cookie'
let supOperationCode = ""
// let supPageAuthType = ''
const ExtraOperation = (props) => {
    const [adminForm] = Form.useForm();
    const [mallForm] = Form.useForm();
    const [supplierForm] = Form.useForm();
    const [ecForm] = Form.useForm();

    const [state, setState] = useState({
        // 跳转类型(1001=企业端,1002=商城后台，1003=商城前端，1004=供应商,1005=选品工具,1007=O2O, 1008数据中台)
        operationList: [
        {
            name: '数据中台',
            popover: false,
            loginType: 1008,
            permission: false,
            url: getFullLink(dataCenterUrl, '/bigdata')
        },
        {
            name: 'C端运营中心',
            popover: false,
            loginType: 1006,
            // permission: hasPermission(['product_tool']),
            permission: false,
            url: getFullLink(ocUrl, '/oc')
        }, {
            name: 'O2O平台',
            popover: false,
            loginType: 1007,
            permission: false,
            url: ''
        }, {
            name: '选品工具',
            popover: false,
            loginType: 1005,
            // permission: hasPermission(['product_tool']),
            permission: false,
            url: getFullLink(selectionUrl, '/selection')
        }, {
            name: '供应商系统',
            popover: {
                visible: false
            },
            loginType: 1004,
            permission: false,
            url: supplierUrl,
            form: {
                ref: supplierForm,
                supplier: {
                    params: {
                        key: 'kwd',
                        componentValue: 1028,
                    },
                    // pcode: pCodes.role.authority,
                    request: '/user/combo/box/partskip',
                    id: '',
                    supplierName: ''
                },
            },
        }, {
            name: '企业端',
            popover: {
                visible: false
            },
            permission: false,
            loginType: 1001,
            form: {
                ref: ecForm,
                customer: {
                    params: {
                        key: 'kwd',
                        componentValue: 1023
                    },
                    ref: useRef(),
                    pcode: 'customer_two_enterprise',
                    request: '/operation/customer/dataAuth/comboBox',
                    id: '',
                    customerName: ''
                },
            },
            url: enterpriseUrl
        }, {
            name: '商城后台',
            permission: false,
            popover: {
                visible: false
            },
            // /yueban/?sign=1003597&type=operationManagement
            loginType: 1002,
            form: {
                ref: adminForm,
                website: {
                    params: {
                        key: 'kwd',
                        componentValue: 1024,
                        paramType: 1002
                    },
                    ref: useRef(),
                    request: '/user/combo/box/partskip',
                    mallPath: '',
                    id: ''
                },
                customer: {
                    params: {
                        key: 'kwd',
                        componentValue: 1023

                    },
                    ref: useRef(),
                    // pcode: pCodes.role.authority,
                    request: '/user/combo/box/partskip',
                    id: ''
                }
            },
            url: adminUrl
        }, {
            name: '商城端',
            permission: false,
            popover: {
                visible: false
            },
            loginType: 1003,
            form: {
                ref: mallForm,
                website: {
                    params: {
                        key: 'kwd',
                        componentValue: 1024,
                        paramType: 1002
                    },
                    ref: useRef(),
                    request: '/user/combo/box/partskip',
                    mallPath: '',
                    id: ''
                },
                customer: {
                    params: {
                        key: 'kwd',
                        componentValue: 1023,
                     },
                    ref: useRef(),
                    // pcode: pCodes.role.authority,
                    request: '/user/combo/box/partskip',
                    id: ''
                }
            },
            url: mallUrl
        }],
        initialValues: {
            customerId: '',
            supplierId: '',
            websiteId: ''
        }
    })
    const [confirmLoading, setConfirmLoading] = useState(false);
    const initForm = (initData) => {
        setState({
            ...state,
            operationList: state.operationList.map(el => {
                let permission = true
                let customerId = 0
                let websiteId = 0
                switch (el.loginType) {
                    case 1001:
                        permission = initData.customer
                        customerId = initData.customerId ? (initData.customerId + '') : undefined
                        ecForm.setFieldsValue({
                            customerId: customerId
                        })
                        break;
                    case 1002:
                        permission = initData.website
                        customerId = initData.websiteCustomerId ? (initData.websiteCustomerId + '') : undefined
                        websiteId = initData.websiteId ? (initData.websiteId + '') : undefined
                        adminForm.setFieldsValue({
                            customerId: customerId,
                            websiteId: websiteId
                        })
                        break;
                    case 1003:
                        permission = initData.mall
                        customerId = initData.mallCustomerId ? (initData.mallCustomerId + '') : undefined
                        websiteId = initData.mallId ? (initData.mallId + '') : undefined
                        mallForm.setFieldsValue({
                            customerId: customerId,
                            websiteId: websiteId
                        })
                        break;
                    case 1004:
                        permission = initData.supplier
                        el.form.supplier.params.paramType = initData.supPageAuthType === 1 ? 1001 : initData.supPageAuthType === 2 ? 1002 : ''
                        supplierForm.setFieldsValue({
                            supplierId: initData.supplierId ? (initData.supplierId + '') : undefined
                        })
                        break;
                    case 1005:
                        permission = initData.productTool
                        break;
                    case 1006:
                        permission = initData.b2cOperationCenter
                        break;
                    case 1007:
                        permission = initData.o2oPlatform
                        break;
                    case 1008:
                        permission = hasPermission(['big_data'])
                        break;
                    default:
                        break;
                }
                return el.popover ? {
                    ...el,
                    permission,
                    form: {
                        ...el.form,
                        customer: el.form.customer ? {
                            ...el.form.customer,
                            id: customerId
                        } : undefined,
                        website: el.form.website ? {
                            ...el.form.website,
                            id: websiteId
                        } : undefined,
                        supplier: el.form.supplier ? {
                            ...el.form.supplier,
                            id: initData.supplierId ? (initData.supplierId + '') : undefined
                        } : undefined
                    },
                    popover: {
                        visible: false
                    }
                } : { ...el, permission }
            })
        });
    };
    useEffect(() => {
        let didUnmounted = false
        const hasLogin = Cookies.get('token')
        if (didUnmounted || !hasLogin) {
            return () => {
                didUnmounted = true
            }
        }
        const getList = () => {
            // GET /user/home/index/partskip
            props.$http.get(`/home/index/partskip`, {
                // headers: { OperationCode: 'operation_website_two_mall' }
            }).then(res => {
                if (!res || res.result === null) return
                supOperationCode = res.result && res.result.supOperationCode
                // supPageAuthType = res.result && res.result.supPageAuthType
                initForm(res.result)
            }).catch(() => { })
        }
        getList()
        return () => {
            didUnmounted = true
        }
    }, [])
    const handleCancel = (record, formRef, visible) => {
        record.form.customer && (record.form.customer.id = '')
        record.form.website && (record.form.website.id = '')
        record.form.supplier && (record.form.supplier.id = '')
        record.form?.customer?.ref?.current?.handleSearch('')
        record.form?.website?.ref?.current?.handleSearch('')
        record.form?.supplier?.ref?.current?.handleSearch('')
        !visible && formRef.resetFields()
        setState({
            ...state,
            operationList: state.operationList.map(el => {
                if (el.loginType === record.loginType) {
                    return {
                        ...record,
                        popover: {
                            visible: false
                        },
                    }
                }
                return el.popover ? {
                    ...el,
                    popover: {
                        visible: false
                    }
                } : el
            })
        });
    };
    const geto2oFullLink = () => {
         props.$http.get(`${appUrl}/operation/o2o/getSecret`, {
            headers: { OperationCode: 'o2o_platform' }
        }).then(res => {
            if (res.success) {
                window.open(`${o2oUrl}?token=${res.result.secret}`, '_blank')
            }

        }).catch(() => { })
    }
    const onConfirm = (record, formRef) => {
        console.log(record, formRef)
        setConfirmLoading(true);
        return new Promise((resolve, reject) => {
            formRef.validateFields().then((values) => {
                let params = {
                    authType: record.loginType === 1001 ? 1001 : 1002,
                    id: record.loginType === 1001 ? record.form?.customer?.id : record.form?.website?.id
                }
                // 供应商登录
                if (record.loginType === 1004) {
                    params = {
                        // supplierCode: "SUP170105",
                        supplierId: record.form.supplier.id,
                        supplierName: record.form.supplier.supplierName || undefined
                    }
                    // console.log(supOperationCode, 'supOperationCode')
                   if (!supOperationCode) {
                    setConfirmLoading(false);
                    message.error('联系管理员设置权限')
                    reject(null)
                    return
                   }
                    props.$http.post(`/skip/supplier`, params, {
                        headers: { OperationCode: supOperationCode }
                    }).then(res => {
                        setConfirmLoading(false);
                        if (!res || !res.success || res.result === null) {
                            reject(null)
                            return
                        }
                        setConfirmLoading(true);
                        props.$http.post(`/link/log/add/partskip`, {
                            linkId: +record.form.supplier.id,
                            linkType: record.loginType,
                            linkName: ''
                        }).then(() => {
                            setConfirmLoading(false);
                            let url = record.url + parseQueryToString({
                                loginName: res.result.loginName,
                                token: res.result.token,
                                userId: res.result.userId
                            })
                            // handleCancel(record, formRef)
                            // console.log(url, 'record.url');
                            window.open(url, '_blank')
                            resolve(null)
                        }).catch((err) => {
                            console.log(err);
                            reject(err)
                            setConfirmLoading(false)
                        })
                    }).catch((err) => {
                        console.log(err);
                        reject(err)
                        setConfirmLoading(false)
                    })
                    return
                }
                //商城后台没有权限不掉跳转接口
                if (record.loginType === 1002 && !record.permission) {
                    console.log(22222)
                    setConfirmLoading(false);
                    let url = record.url
                    url += '/' + record.form.website.mallPath + '/?type=operationManagement'
                    window.open(url, '_blank')
                    resolve(null)
                    return
                }
                // 商城端登录
                props.$http.get(`${appUrl}/operation/data/operation/create/auth`, {
                    params,
                    headers: { OperationCode: record.loginType === 1001 ? 'customer_two_enterprise' : 'operation_website_two_mall' }
                }).then(res => {
                    setConfirmLoading(false);
                    if (!res || !res.success || res.result === null) {
                        reject(null)
                        return
                    }
                    setConfirmLoading(true);

                    props.$http.post(`/link/log/add/partskip`, {
                        linkId: record.loginType === 1001 ? +record.form?.customer?.id : +record.form?.website?.id,
                        linkType: record.loginType,
                        linkName: ''
                    }).then(() => {
                        setConfirmLoading(false);
                        // POST /user/link/log/add/partskip
                        // message.info('功能建设中,敬请期待~')
                        let url = record.url
                        if (record.loginType === 1003) {
                            url += '?mallPath=' + record.form.website.mallPath
                        } else if (record.loginType === 1002) {
                              url += '/' + record.form.website.mallPath + '/?sign=' + res.result + '&type=operationManagement'
                        } else if (record.loginType === 1001) {
                               url += '?sign=' + res.result
                        }
                        // console.log(url, 'record.url');
                        // handleCancel(record, formRef)
                        window.open(url, '_blank')
                        resolve(null)
                    }).catch(() => {setConfirmLoading(false); resolve(null);})
                }).catch((err) => {
                    console.log(err);
                    reject(err)
                    setConfirmLoading(false)
                })
            }).catch((err) => {
                console.log(err)
                reject(err)
                setConfirmLoading(false)
            })
        });
    }
    return (
        <div className='operation-list tr'>
            {
            state.operationList.filter(el => {
              if (el.loginType === 1001 || el.loginType === 1002) {
                return true
              } else {
               return  el.permission
              }
            }).map((record, index) => {
               return  record.popover && (record.loginType === 1002 || record.permission) ?
                 <Popconfirm
                key={index}
                okButtonProps={{ loading: confirmLoading }}
                // onCancel={() => handleCancel(record, record.form.ref)}
                // onVisibleChange={(val) => handleCancel(record, record.form.ref, val)}
                icon={''}
                placement="topRight"
                title={<Form
                    name="basic"
                    style={{ width: '340px' }}
                    form={record.form.ref}
                    // labelCol={{ span: 8 }}
                    wrapperCol={{ span: 18 }}
                    initialValues={state.initialValues}
                    autoComplete="off"
                >
                    {record.form.customer ? <Form.Item
                        label="客户名称"
                        name="customerId"
                        rules={[{ required: true, message: '客户名称不能为空' }]}
                    >
                        <YzhSelect
                            // 组件的配置
                            style={{
                                width: '100%'
                            }}
                            mode=""
                            onChange={(val, option) => {
                                // record.form.customer.value = val
                                // console.log(val, option, 'option');
                                setState({
                                    ...state,
                                    operationList: state.operationList.map(el => {
                                        return el.popover && record.loginType === el.loginType ? {
                                            ...el,
                                            form: {
                                                ...el.form,
                                                customer: el.form.customer ? {
                                                    ...el.form.customer,
                                                    id: val || el.form.customer.id || undefined
                                                } : undefined,
                                                website: el.form.website ? {
                                                    ...el.form.website,
                                                    id: option.length ? el.form.website.id : ''
                                                } : undefined
                                            }
                                        } : el.popover && (record.loginType === 1002 || record.loginType === 1003) ? {
                                            ...el,
                                            form: {
                                                ...el.form,
                                                website: el.form.website ? {
                                                    ...el.form.website,
                                                    id: option.length ? el.form.website.id : ''
                                                } : undefined
                                            }
                                        } : el
                                    })
                                });
                                if (!option.length) {
                                    record.form.ref?.resetFields(['websiteId'])
                                    record.form.website?.ref?.current?.handleSearch('')
                                }
                            }}
                            request={record.form.customer.request}
                            pcode={record.form.customer.pcode}
                            params={{
                                ids: record.form.customer.id || undefined,
                                ...record.form.customer.params
                            }}
                            placeholder="请选择或输入客户名称"
                            props={{
                                value: 'comboKey',
                                label: 'comboValue',
                                disabled: { key: '-2', reverse: true }
                            }} />
                    </Form.Item> : null}
                    {record.form.website ? <Form.Item
                        label="商城名称"
                        name="websiteId"
                        rules={[{ required: true, message: '商城名称不能为空' }]}
                    >
                        <YzhSelect
                            // 组件的配置
                            style={{
                                width: '100%'
                            }}
                            ref={record.form.website.ref}
                            mode=""
                            disabled={!record.form.customer.id}
                            onChange={(val, option) => {
                                // console.log(val, option, option.length ? option.filter(el => el.comboKey === val) : option.comboExtend, 'option');
                                setState({
                                    ...state,
                                    operationList: state.operationList.map(el => {
                                        return el.popover && record.loginType === el.loginType ? {
                                            ...el,
                                            form: {
                                                ...el.form,
                                                website: el.form.website ? {
                                                    ...el.form.website,
                                                    mallPath: option.length ? option.filter(el => el.comboKey === val)[0]?.comboExtend : option.comboExtend,
                                                    id: val || undefined
                                                } : undefined
                                            }
                                        } : el
                                    })
                                });
                            }}
                            request={record.form.website.request}
                            pcode={record.form.website.pcode}
                            params={{
                                ids: record.form.website.id || undefined,
                                pid: record.form.customer.id,
                                ...record.form.website.params
                            }}
                            placeholder="请选择或输入商城名称"
                            props={{
                                value: 'comboKey',
                                label: 'comboValue',
                                disabled: { key: '-2', reverse: true }
                            }} />
                    </Form.Item> : null}
                    {record.form.supplier ? <Form.Item
                        label="供应商名称"
                        name="supplierId"
                        rules={[{ required: true, message: '供应商名称不能为空' }]}
                    >
                        <YzhSelect
                            // 组件的配置
                            style={{
                                width: '100%'
                            }}
                            mode=""
                            onChange={(val, option) => {
                                // console.log(option, 'option');
                                setState({
                                    ...state,
                                    operationList: state.operationList.map(el => {
                                        return el.popover && record.loginType === el.loginType ? {
                                            ...el,
                                            form: {
                                                ...el.form,
                                                supplier: el.form.supplier ? {
                                                    ...el.form.supplier,
                                                    supplierName: option.length ? option.filter(el => el.value === val)[0]?.label : option.label,
                                                    id: val || undefined
                                                } : undefined
                                            }
                                        } : el
                                    })
                                });
                            }}
                            request={record.form.supplier.request}
                            pcode={record.form.supplier.pcode}
                            params={{
                                ids: record.form.supplier.id || undefined,
                                ...record.form.supplier.params
                            }}
                            placeholder="请选择或输入供应商名称"
                            props={{
                                value: 'comboKey',
                                label: 'comboValue',
                                disabled: { key: '-2', reverse: true }
                            }} />
                    </Form.Item> : null}
                </Form>}
                onConfirm={() => onConfirm(record, record.form.ref)}
            >
            <Button type="link"> {record.name} </Button>
                </Popconfirm> : record.loginType === 1007 ? <Button onClick={() => geto2oFullLink()} key={index} type="link">{record.name} </Button> :
                <Button key={index} type="link">
                       <a type="link" target={'_blank'} key="link" href={record.url} rel="noreferrer">
                {record.name}
             </a>
            </Button>


           })}
        </div>
    )
}
export default ExtraOperation